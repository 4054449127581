import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/news"
import MainPage from "../news_component/main"
import Hero from '../news_component/slices/Hero'
import Text from '../news_component/slices/Text'
import CustomHTML from '../news_component/slices/CustomHTML'
import Blockquote from '../news_component/slices/Blockquote'
import Meta from '../news_component/slices/Meta'
// import BeforeAfterSlider from '../news_component/slices/BeforeAfterSlider'
import Grid from '../news_component/slices/Grid'
import Slider from '../news_component/slices/Slider/Slider'
import Global from '../news_component/GlobalStyles'
import Seo from "../components/Seo"


const newsTemplate = (props) => {
  const data = props.data.prismic.news;
  const {
    description,
    title,
    _meta,
    font_base_size,
    font_text_color,
    font_header_color,
    desktop_layout_width,
    page_background_color,
    layout_background_color,
    fonts_scale,
    text_font,
    font_heders,
    rubric,
    meta_image,
    page_background_imageSharp
  } = data;
  return (
    <>
    <Layout currentSlug={'/' + rubric + '/news/' + _meta.uid} >
      <Seo title={title[0].text} description={description[0].text} SSLimage={meta_image.url} />
       <Global
        font={font_base_size}
        family={text_font}
        font_family_header={font_heders}
        color={font_text_color}
        header_color={font_header_color}
        scale={fonts_scale}
        maxWidth={desktop_layout_width}
        layout_background_color={layout_background_color}
        page_background_color={page_background_color}
        page_background_imageSharp={page_background_imageSharp}
      />
      <MainPage
        image={page_background_imageSharp}
      >
        <article style={{
        }}>
          {data.body ? data.body.map((slice, idx) => {
            switch (slice.type) {
              case 'hero':
                return <Hero key={`${slice.type}${idx}`} data={slice.primary} />
              // case 'before_after':
              //   return typeof window !== `undefined` ? <BeforeAfterSlider key={`${slice.type}${idx}`}
              //     before={slice.primary.before_imageSharp}
              //     after={slice.primary.after_imageSharp}
              //     width={desktop_layout_width}
              //     devider_color={slice.primary.devider_color} /> : null
              case 'text':
                return <Text key={`${slice.type}${idx}`} data={slice.primary} />
              case 'custom_html':
                return <CustomHTML key={`${slice.type}${idx}`} data={slice.primary} />
              case 'blockquote':
                return <Blockquote key={`${slice.type}${idx}`} data={slice.primary} />
              case 'grid':
                return <Grid key={`${slice.type}${idx}`} data={slice.primary} fields={slice.fields} width={desktop_layout_width} />
              case 'meta':
                return <Meta key={`${slice.type}${idx}`} data={slice.primary} fields={slice.fields} width={desktop_layout_width} />
              case 'slider':
                  return <Slider key={`${slice.type}${idx}`} data={slice.primary} links={slice.fields} font_base_size={font_base_size}/>
              default:
                return null
            }
          }) : null}
        </article>

        </MainPage>
      </Layout>
    </>
  )
}

export default newsTemplate;

export const pageQuery = graphql`
query NewsQuery($uid: String!, $lang: String!) {
    prismic {
         news(uid: $uid, lang: $lang) {
          description 
          _meta {
            uid
            lang
          }
          title 
          rubric
          desktop_layout_width
          font_base_size
          font_header_color
          meta_image
          font_heders {
            ... on PRISMIC__ExternalLink {
              _linkType
              url
            }
          }
          font_text_color
          fonts_scale
          layout_background_color
          meta_image
          page_background_color
          page_background_image
          page_background_imageSharp {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 98) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          text_font {
            ... on PRISMIC__ExternalLink {
              _linkType
              url
            }
          }
          body {
            ... on PRISMIC_NewsBodyHero {
              type
              label
              primary {
                background_color
                full_width
                hero_headers_color
                hero_image
                hero_imageSharp {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 98) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                hero_text
                horizontal_align
                vertical_align
                text_hero_colors
              }
            }  
            ... on PRISMIC_NewsBodyText {
              type
              label
              primary {
                background_color
                body1
                full_width
                text_header_color
                text_main_color
                custom_width
              }
            }
            ... on PRISMIC_NewsBodyBlockquote {
              type
              label
              primary {
                background_color
                blockqoutetext
                full_width
                text_header_color
                text_main_color
                custom_width
              }
            }
            ... on PRISMIC_NewsBodySlider {
              type
              label
              fields {
                slider_external_link {
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                }
                slider_image
                slider_imageSharp {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 98) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
                text_slider
              }
              primary {
                background_color
                font_header_color1
                font_text_color1
                horizontal_align
                vertical_align
                full_width
                image_on_page
                is_image_background
              }
            }     
            ... on PRISMIC_NewsBodyBefore_after {
              type
              label
              primary {
                before_image
                before_imageSharp {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 98) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                after_image
                after_imageSharp {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 98) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                devider_color
              }
            } 
            ... on PRISMIC_NewsBodyCustom_html {
              type
              label
              primary {
                customhtml
                full_width
                frame_width
                frame_height
              }
            }
            ... on PRISMIC_NewsBodyMeta {
              type
              label
              primary {
                font_color
                background_color
                author_link
                full_width
                show_share_links
                date
              }
            }
            ... on PRISMIC_NewsBodyGrid {
              type
              label
              fields {
                link {
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                }
                grid_image
                grid_imageSharp {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 98) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                text_grid
                background_color
              }
              primary {
                background_color
                font_header_color1
                font_text_color1
                full_width
                gap
                horizontal_align
                vertical_align
                items_on_first_row
                items_on_second_row
              }
            }
          }
        }  
        allNewss {
          edges {
            node {
              _meta {
                uid
              }
            }
          }
        }
      }
    }
`