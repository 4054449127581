import React from 'react'
import Img from "gatsby-image"
import styled from "styled-components"

const Main = styled.main`
  margin: 0 auto;
`;

const MainPage = ({
  font,
  gradient,
  image,
  color,
  children
}) => {

  const background = image || gradient || null;

  return (
    <Main
      image={image}
      gradient={gradient}
      background={background}
      color={color}
      font={font}
    >
     {image ? <Img fluid={image.childImageSharp.fluid} loading="lazy" style={{
          overflow: 'hidden',
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: -1
        }} /> : null}    
      {children}
    </Main>
  )
}

export default MainPage