import React from 'react'
import Img from "gatsby-image"
import styled from "styled-components"
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby'
import cn from 'classnames'

const HeroTextWrapper = styled.div`
  ${props => props.is_image_background && !props.fullWidth ? `padding: calc(5.5rem + 46px);` : 'padding: 1.05rem;'};
  ${props => props.is_image_background && `position: absolute; transform: translateX(-50%);`};
  max-width: ${props => props.width ? `${props.width}px` : '1280px'};
  width: 100%;
  top: 0;
  right: 0;
  left: 50%;
  
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  text-align: ${props => props.horizontal_align};
  justify-content: ${props =>props.vertical_align === 'top' ? 'flex-start' : props.vertical_align === 'bottom' ? 'flex-end' : 'center'};
  p {
    width: 100%;
  }
  @media screen and (max-width:  ${props => props.maxWidth ? props.maxWidth + 'px' : '1280px'}) {
    padding: 1.05rem;
  }
  *:first-child {
    margin-top: 0;

  }
  h1, h2, h3, h4, h5 {
    margin-bottom: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
`

const SlideLink = ({slider_external_link, children}) => {
  const isLocalLink = slider_external_link && slider_external_link.url.indexOf('https://aboutpeople.info/') === 0;
  const slider_internal_link = isLocalLink ? slider_external_link.url.replace("https://aboutpeople.info", "") : false;

  return (
    <>
      {slider_external_link && !slider_internal_link && <a href={slider_external_link.url} target="_blank"> {children} </a>}
      {slider_internal_link && <Link to={slider_internal_link}> {children} </Link>}
      {!slider_external_link && !slider_internal_link && children}
    </>
  )
}
const Slide = ({
  is_image_background,
  text_slider,
  slider_imageSharp,
  slider_external_link,
  horizontal_align,
  vertical_align,
  fullWidth
}) => {
  return (
    <SlideLink
      slider_external_link={slider_external_link}
    >
      {slider_imageSharp && <Img className={cn({'add-overlay': is_image_background})} fluid={slider_imageSharp.childImageSharp.fluid} loading="eager" />}
      {text_slider && <HeroTextWrapper horizontal_align={horizontal_align} vertical_align={vertical_align} is_image_background={is_image_background} fullWidth={fullWidth}><RichText render={text_slider}></RichText></HeroTextWrapper>}
    </SlideLink>
  )
}

export default Slide