import React, {useEffect} from 'react'
import styled from "styled-components"
import { RichText } from 'prismic-reactjs';

const TextSlice = styled.section`
  ${props => props.fullWidth  ? `max-width 100%;` : props.customWidth ? `max-width: ${props.customWidth}px` : ''};   
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.05rem;
  padding-right: 1.05rem;
  background-color: ${props => props.background_color};
  color: ${props => props.text_color || '#000'};
  img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  h1, h2, h3 {
    color: ${props => props.header_color || '#000'};
  }
  .center-align {
    text-align: center;
  }
  span.center-align {
    display: inline-block;
    text-align: center;
  }
  .right-align {
    text-align: right;
  }
  span.right-align {
    display: inline-block;
    text-align: right;
  }
`;

const Text = ({
  data,
  children
}) => {
  useEffect(() => {
    const infogramm = function(e, i, n, s) {
      var t = "InfogramEmbeds";
      var d = e.getElementsByTagName("script")[0];
      if (window[t] && window[t].initialized) window[t].process && window[t].process();
      else if (!e.getElementById(n)) {
        var o = e.createElement("script");
        o.async = 1 
        o.id = n 
        o.src = "https://e.infogram.com/js/dist/embed-loader-min.js" 
        d.parentNode.insertBefore(o, d)
      }
    }
    document.addEventListener('onload', infogramm(document, 0, "infogram-async"))
  })
  const {
    background_color,
    body1,
    full_width,
    text_header_color,
    text_main_color,
    custom_width
  } = data;

  return (
    <TextSlice
      background_color={background_color}
      header_color={text_header_color}
      text_color={text_main_color}
      fullWidth={full_width}
      customWidth={custom_width}
    >
      <RichText render={body1}/>
      {children}
    </TextSlice>
  )
}

export default Text