import React from 'react'
import styled from "styled-components";
import Carousel from './Carousel'

const SliderSlice = styled.section`
  ${props => props.fullWidth ? `max-width 100%;` : ''};   
  margin-left: auto;
  margin-right: auto;
  ${props => !props.fullWidth ? `padding-left: 1.05rem;` : ''};
  ${props => !props.fullWidth ? `padding-right: 1.05rem;` : ''};
  ${props => props.background_color && `background-color: ${props.background_color}`};
  color: ${props => props.is_image_background && !props.text_colors ? '#fff' : props.text_colors};
  h1, h2, h3, h4, h5 {
    color: ${props => props.is_image_background && !props.header_color ? '#fff' : props.header_color };
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const Slider = ({
  data,
  links,
  font_base_size,
}) => {
  const {
    background_color,
    font_header_color1,
    font_text_color1,
    image_on_page,
    horizontal_align,
    vertical_align,
    full_width,
    is_image_background
  } = data;

  const { slider_imageSharp } = links;
  return (
    <SliderSlice
      image={slider_imageSharp}
      background_color={background_color}
      header_color={font_header_color1}
      text_colors={font_text_color1}
      fullWidth={full_width}
      is_image_background={is_image_background}
    >
      <Carousel 
        img={links}
        is_image_background={is_image_background}
        image_on_page={image_on_page}
        font_base_size={font_base_size}
        full_width={full_width}
        horizontal_align={horizontal_align}
        vertical_align={vertical_align}
      />
    </SliderSlice>
  )
}

export default Slider