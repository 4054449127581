import React, { useRef } from 'react';
import Glide from 'react-glidejs';
import Slide from './Slide'
import 'react-glidejs/dist/index.css';
import './slider.scss'


const Carousel = ({ 
    img,
    is_image_background,
    image_on_page,
    font_base_size,
    full_width,
    horizontal_align,
    vertical_align
  }) => {
  const gliderRef = useRef(null);
  return (
      <Glide
        ref={gliderRef}
        type="carousel"
        perView={image_on_page}
        gap={font_base_size * 1.05}
        animationDuration={1200}
        // autoplay={3000}
        breakpoints={{
          767: {
            perView: 1,
            gap: 16,
          },
          320: {
            perView: 1,
            gap: 16,
          },
        }}
        
      >
        {img.map((item, idx) => <Slide 
          is_image_background={is_image_background}
          key={idx}
          fullWidth={full_width}
          text_slider={item.text_slider}
          slider_imageSharp={item.slider_imageSharp}
          slider_external_link={item.slider_external_link}
          horizontal_align={horizontal_align}
          vertical_align={vertical_align}
        />)}
      </Glide>
  );
};
export default Carousel
