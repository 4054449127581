import React from 'react'
import styled from "styled-components"
import { RichText } from 'prismic-reactjs';

const BlockquoteSlice = styled.blockquote`
  ${props => props.fullWidth  ? `max-width 100%;` : props.customWidth ? `max-width: ${props.customWidth}px` : ''};   
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
	padding: 1.05rem;
	padding-left: 2.75rem;
  background-color: ${props => props.background_color};
	color: ${props => props.text_color || '#000'};
  &:after {
    content: close-quote;
    position: absolute;
    font-size:0;
    top: 0;
    left: 1.05rem;
    bottom: 0;
    width: 4px;
    background-color: ${props => props.header_color || '#000'}
  }
	&:before {
		color: ${props => props.text_color || '#000'};
		content: open-quote;
		font-size: 4em;
		line-height: 1;
		margin-right: 0.25em;
	}
  img {
    display: block;
		max-width: 80px;
		border-radius: 50%;
  }
  h1, h2, h3 {
    color: ${props => props.header_color || '#000'};
  }
  .center-align {
    text-align: center;
  }
  span.center-align {
    display: inline-block;
    text-align: center;
  }
  .right-align {
    text-align: right;
  }
  span.right-align {
    display: inline-block;
    text-align: right;
  }
`;

const Blockquote = ({
  data,
  children
}) => {
  const {
    background_color,
    blockqoutetext,
    full_width,
    text_header_color,
    text_main_color,
    custom_width
  } = data;

  return (
    <BlockquoteSlice
      background_color={background_color}
      header_color={text_header_color}
      text_color={text_main_color}
      fullWidth={full_width}
      customWidth={custom_width}
    >
      <RichText render={blockqoutetext}/>
      {children}
    </BlockquoteSlice>
  )
}

export default Blockquote