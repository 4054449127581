import React from 'react'
import Img from "gatsby-image"
import styled from "styled-components"
import { RichText } from 'prismic-reactjs';

const HeroSlice = styled.section`
  position: relative;
  ${props => props.fullWidth ? `max-width 100%;` : ''};
  max-height: calc(100vh - 66px);
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  background-color: ${props => props.background_color};
  color: ${props => props.text_color ? props.hasImage && '#fff' : '' };
  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.header_color || '#fff'};
  }

  ${props => props.hasImage && `&:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.2;
    z-index: 1;
  }`}
  
`;

const HeroTextWrapper = styled.div`
  ${props => props.hasImage && `position: absolute; transform: translateX(-50%);`}

  ${props => props.fullWidth ? `` : 'margin-left: auto; margin-right: auto'};
  max-width: ${props => props.width ? `${props.width}px` : '1280px'};
  width: 100%;
  top: 0;
  right: 0;
  left: 50%;
  bottom: 0;
  display: flex;
  text-align: ${props => props.horizontal_align};
  align-items: ${props =>props.vertical_align === 'top' ? 'flex-start' : props.vertical_align === 'bottom' ? 'flex-end' : 'center'};
  z-index: 1;
  & > div {
    width: 100%;
    *:first-child {
      margin-top: 0;
    }
    *:last-child {
      margin-bottom: 0;
    }
  }
`
const Hero = ({
  data,
  children
}) => {
  const {
    full_width,
    hero_headers_color,
    hero_imageSharp,
    hero_text,
    horizontal_align,
    text_hero_colors,
    vertical_align,
    background_color
  } = data;

  return (
    <HeroSlice
      image={hero_imageSharp}
      header_color={hero_headers_color}
      text_color={text_hero_colors}
      fullWidth={full_width}
      hasImage={!!hero_imageSharp}
      background_color={background_color}
    >
      {hero_imageSharp ? <Img fluid={hero_imageSharp.childImageSharp.fluid} loading="lazy" style={{maxHeight: '100vh'}}  /> : null}
      <HeroTextWrapper
        horizontal_align={horizontal_align}
        vertical_align={vertical_align}
        hasImage = {!!hero_imageSharp}
        className="hero-text-wrapper"
       >
        <div>
          <RichText render={hero_text || []} /> 
        </div>
      </HeroTextWrapper>
      {children}
    </HeroSlice>
  )
}

export default Hero