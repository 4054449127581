import React from 'react'
import styled from "styled-components"
import { RichText } from 'prismic-reactjs';
import { format, parseISO } from 'date-fns'
import FooterShareList from '../../components/FooterShareList';
const MetaSlice = styled.section`
  ${props => props.fullWidth  ? `max-width 100%;` : props.customWidth ? `max-width: ${props.customWidth}px` : ''};   
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 1.05rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.background_color};
  color: ${props => props.font_color};
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  & > div {
    flex: 1 1 33%;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  .b-footer-share__list-item svg path {
    fill: ${props => props.font_color || '#000'} !important;
  }
  a {
    color: ${props => props.font_color};
    text-decoration: none;
    @media screen and (max-width: 768px) {
      display: inline-block;
      margin-bottom: 7px;
      margin-top: 3px;
    }
  }
  .meta-date {
    text-align: right;
    @media screen and (max-width: 768px) {
      order: 0;
    }
  }
`;
const MetaSMM = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    order: -1;
  }
  .b-footer-share {
    display: flex;
  }
`
const Meta = ({
  data
}) => {
  const {
    background_color,
    full_width,
    font_color,
    author_link,
    show_share_links,
    date
  } = data;

  let parsedDate = parseISO(date)
  return (
    <MetaSlice
      background_color={background_color}
      font_color={font_color}
      fullWidth={full_width}
    >
      <div><RichText render={author_link}/></div>
      {show_share_links && 
        <MetaSMM>
          <FooterShareList />
        </MetaSMM>
      }
      <div className="meta-date">
        {date && format(parsedDate, 'dd.MM.yyyy')}
      </div>
      
    </MetaSlice>
  )
}

export default Meta