import React from 'react'
import styled from "styled-components"
import cn from 'classnames'
const CustomEmbed = styled.section`
  ${props => props.hasImage && `position: absolute; transform: translateX(-50%);`}
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 0!important;
  .aspectratio {
    padding-top: ${props => {
      return props.aspectratio.split('_').reduce((acc, current) => acc ? current / acc * 100 + '%' : acc = current, null)
    }};
  }
  .aspectratio__embed {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
  }
`
const CustomHTML = ({
  data,
}) => {
  const {frame_width, frame_height, full_width, customhtml} = data;
  return (
    frame_width && frame_height
      ? <CustomEmbed aspectratio={`${frame_width}_${frame_height}`} className={cn("custom-embed", {"custom-embed--full" : full_width})}>
          <div className="aspectratio"></div>
          <div className="aspectratio__embed" dangerouslySetInnerHTML={{ __html: customhtml && customhtml[0].text }}></div>
        </CustomEmbed>
      :<section style={{marginLeft: 'auto', marginRight: 'auto', display: 'flex'}} className={cn("custom-embed", {"custom-embed--full" : full_width})}>
        <div dangerouslySetInnerHTML={{ __html: customhtml && customhtml[0].text }}></div>
      </section>
  )
}

export default CustomHTML