import React from 'react'
import Img from "gatsby-image"
import styled from "styled-components"
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby'

const GridSlice = styled.section`
  ${props => props.fullWidth ? `max-width 100%;` : ''};
  margin-left: auto;
  margin-right: auto;
  ${props => props.background_color && `background-color: ${props.background_color}`};

  color: ${props => props.text_colors};
  
  h1, h2, h3 {
    color: ${props => props.header_color};
  }
  a {
    text-decoration: none;
    color: inherit;
    line-height: 1;
    font-size: 0;
    .gatsby-image-wrapper {
      height: 100%;
      img {
        transition: all 0.5s ease!important;
        will-change: transform;
      }
      z-index: 1;
    }
  }
  a:hover {
    .gatsby-image-wrapper img {
      transform: scale(1.05);
      z-index: 1;
    }
  }
`;
const GridRow = styled.div`
  ${props => `grid-template-columns: repeat(${props.itemsAmount}, 1fr)`};
  display: grid;
  ${props => props.gap && `gap: 1.05rem; margin-bottom: 1.05rem`};
  @media screen and (max-width: 768px) {
    display: block;
  }

`
const GridText = styled.div`
  ${props => props.background_color && `background-color: ${props.background_color}`};
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: ${props => props.horizontal_align};
  justify-content: ${props =>props.vertical_align === 'top' ? 'flex-start' : props.vertical_align === 'bottom' ? 'flex-end' : 'center'};
  padding: 1.05rem;
  color: ${props => props.background_color && !props.text_colors ? '#fff' : props.text_colors};
  h1, h2, h3, h4, h5 {
    color: ${props => props.background_color && !props.header_color ? '#fff' : props.header_color };
  }
  *:first-child {
    margin-top: 0
  }
  *:last-child {
    margin-bottom: 0
  }
`
const Item = ({item, horizontal_align, vertical_align, font_header_color1, font_text_color1, background_color}) => {
  return(
    <>
      {item && item.grid_imageSharp && <Img fluid={item.grid_imageSharp.childImageSharp.fluid} loading="lazy" />}
      {item && item.text_grid && !item.grid_imageSharp  && <GridText 
        background_color={item.background_color ? item.background_color : background_color} 
        vertical_align={vertical_align} 
        horizontal_align={horizontal_align}
        header_color={font_text_color1}
        text_colors={font_header_color1}
        hasImage={!!item.grid_imageSharp}
      >
        {item && <RichText render={item.text_grid} />}
      </GridText> }
    </>
  )
}

const LinkItem = ({link, children}) => {
  const isLocalLink = link && link.url.indexOf('https://aboutpeople.info/') === 0;
  const slider_internal_link = isLocalLink ? link.url.replace("https://aboutpeople.info", "") : false;

  return (
    <>
      {link && !slider_internal_link && <a href={link.url} target="_blank"> {children} </a>}
      {slider_internal_link && <Link to={slider_internal_link}> {children} </Link>}
      {!link && !slider_internal_link && children}
    </>
  )
} 
const Grid = ({
    data,
    fields,
    width,
}) => {
    const {
        background_color,
        font_header_color1,
        font_text_color1,
        full_width,
        gap,
        horizontal_align,
        vertical_align,
        items_on_first_row,
        items_on_second_row,
    } = data;
    const firstRowArray = Array.from(Array(parseInt(items_on_first_row)), (x, index) => index + 1)
    const secondRowArray = Array.from(Array(parseInt(items_on_second_row)), (x, index) => index + 1)
    return (
        <GridSlice
            color={background_color}
            header_color={font_header_color1}
            text_colors={font_text_color1}
            width={width}
            fullWidth={full_width}
            background_color={background_color}
        >
            <GridRow itemsAmount={items_on_first_row} gap={gap}>
                {firstRowArray.map((item, index) => fields[index] && fields[index].link 
                ? <LinkItem link={fields[index].link} key={index}> 
                  <Item 
                    vertical_align={vertical_align}   
                    horizontal_align={horizontal_align}
                    font_header_color1={font_header_color1}
                    font_text_color1={font_text_color1} 
                    background_color={background_color}
                    item={fields[index]} key={index}/> 
                </LinkItem> :
                <Item 
                  vertical_align={vertical_align} 
                  horizontal_align={horizontal_align}
                  font_header_color1={font_header_color1}
                  font_text_color1={font_text_color1} 
                  background_color={background_color}
                  item={fields[index]} key={index}/> 
                )}
            </GridRow>
            <GridRow itemsAmount={items_on_second_row} gap={gap}>
                {secondRowArray.map((item, index) => fields[index + firstRowArray.length] && fields[index + firstRowArray.length].link 
                ? <LinkItem link={fields[index + firstRowArray.length].link} key={index}> 
                  <Item 
                  vertical_align={vertical_align} 
                  horizontal_align={horizontal_align}
                  font_header_color1={font_header_color1}
                  font_text_color1={font_text_color1} 
                  background_color={background_color}
                  item={fields[index + firstRowArray.length]} key={index}/> 
                </LinkItem> :
                <Item 
                  vertical_align={vertical_align} 
                  horizontal_align={horizontal_align}
                  font_header_color1={font_header_color1}
                  font_text_color1={font_text_color1} 
                  background_color={background_color}
                  item={fields[index + firstRowArray.length]} key={index}/> 
              )}
            </GridRow>
        </GridSlice>
    )
}

export default Grid