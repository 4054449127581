import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Header from '../components/Header'
import Footer from '../components/Footer'

const myStaticQuery = graphql`
query layoutNewsQuery {
  allContentfulTag: allContentfulTag {
    edges {
      node {
        items {
          ... on ContentfulLessons {
            slug
          }
          ... on ContentfulMediaLibrary {
            slug
          }
          ... on ContentfulLibrary {
            slug
          }
          ... on ContentfulNko {
            slug
          }
          ... on ContentfulVideoLibrary {
            slug
          }
        }
        title
        displayTitle
      }
    }
  }
  about: contentfulAbout {
    slug
    title
  }
}
`

const TemplateWrapper = ({ currentSlug, news, children }) => {
  return (
  <StaticQuery
    query={`${myStaticQuery}`}
    render={props => {
      const { allContentfulTag, about} = props
      return (
        <>
          <Header allContentfulTag={allContentfulTag} about={about} slug={currentSlug} />
          {children}
          <Footer />
        </>
      )
    }}
  />
  )
}

export default TemplateWrapper
