import React from "react"
import { createGlobalStyle } from 'styled-components'

const SCALE_CONFIG = (width) => {
 return ({
  '1.33': ` 
    h1 {
      margin-top: 0;
      font-size: 4.209em;
    }
    h2 {font-size: 3.157em;}
    
    h3 {font-size: 2.369em;}
    
    h4 {font-size: 1.777em;}
    
    h5 {font-size: 1.777rem}

    h6 {font-size: 1.333rem;}
    
    small, .text_small {font-size: 0.75em;}
    @media screen and (max-width:  ${width ? width + 'px' : '1280px'}) {
      h1, h2, h3, h4 {
        font-size: 1.333em;
      }
    }
    `,

  '1.5': `
    h1 {
      margin-top: 0;
      font-size: 7.594em;
    }
    
    h2 {font-size: 5.063em;}
    
    h3 {font-size: 3.375em;}
    
    h4 {font-size: 2.25em;}
    
    h5 {font-size: 2.25em;}

    h6 {font-size: 1.5rem;}
    
    small, .text_small {font-size: 0.667em;}
    @media screen and (max-width:  ${width ? width + 'px' : '1280px'}) {
      h1, h2, h3, h4 {
        font-size: 1.5em;
      }
    }
  `,
  '1.618': `
    h1 {
      margin-top: 0;
      font-size: 11.089em;
    }
    
    h2 {font-size: 6.854em;}
    
    h3 {font-size: 4.236em;}
    
    h4 {font-size: 2.618em;}
    
    h5 {font-size: 2.618em;}

    h6 {font-size: 1.618rem;}

    small, .text_small {font-size: 0.618em;}
    @media screen and (max-width:  ${width ? width + 'px' : '1280px'}) {
      h1, h2, h3, h4, h5 {
        font-size: 1.618em;
      }
    }
  `
 })
};

const getFamilyName = (font_url) => {
  let familyName = null;
  if (font_url) {
    const fontNameI = font_url.url.split('=')[1];
    familyName = fontNameI.split('&')[0];
  }
  return familyName;
}

const GlobalStyle = createGlobalStyle`
${props => props.font_family ? `@import url(${props.font_family.url})` : null};
${props => props.font_family_header ? `@import url(${props.font_family_header.url})` : null};

html {
  font-size: ${props => props.font + 'px' || '16px'};
  color: ${props => props.color || '#000'};
  font-family: ${props => props.font_name};
  padding: 0;
  margin: 0;
};
body {
  font-family: ${props => props.font_name};
  padding: 0;
  line-height: 1.65;
  ${props => props.page_background_color ? `background-color: ${props.page_background_color};` : null};
}
*, *:after, *:before {
  box-sizing: border-box;
}

p, iframe {margin-bottom: 1.15rem;}

h1, h2, h3, h4, h5 {
  margin: 2.75rem 0 1.05rem;
  font-weight: 600;
  line-height: 1.15;
  color: ${props => props.header_color};
  font-family: ${props => props.font_name_header};
}
h5, h6 {
  margin: 0;
  display: inline-block;
}

section, blockquote {
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 100%;
  min-height: 100%;
  max-width: ${props => props.maxWidth ? props.maxWidth + 'px' : '1280px'};
  margin-bottom: 1.15rem;
  ${props => props.layout_background_color ? `background-color: ${props.layout_background_color};` : null};
  .hero-text-wrapper {
    padding: 1.05rem;
    max-width: ${props => props.maxWidth ? props.maxWidth + 'px' : '1280px'};
  }
  &:last-child {
    padding-bottom: 2.75rem;
  }
}
article {
  position: relative;
  &:before {
    content: '';
    display: ${props => props.page_background_imageSharp ? 'none' : 'block'};
    position: absolute; 
    ${props => props.layout_background_color ? `background-color: ${props.layout_background_color};` : null};
    left: 50%;
    top: 0;
    bottom: 0;
    width: ${props => props.maxWidth ? props.maxWidth + 'px' : '1280px'};
    transform: translateX(-50%);
    max-width: 100%;
  }
}
@media screen and (max-width:  ${props => props.maxWidth ? props.maxWidth + 'px' : '1280px'}) {
  section, blockquote { 
    max-width: 768px;
    .hero-text-wrapper {
      max-width: 768px;
    }
  }
}
@media screen and (max-width: 768px) {
  .slider__track + div {
    display: none;
  }
  .glide__slide {
    h2, h3, h4 {
      font-size 1.5rem;
    }
  }
}
@media screen and (max-width: 480px) {
  .glide__slide {
    h2, h3, h4 {
      font-size 1rem;
    }
  }
}

section:last-child {
  margin-bottom: 0;
}
.custom-embed {
  padding: 0 1.05rem;
  iframe {
    width: 100%;
    max-width: 100%;
    height: 110%;
  }
  &--full {
    max-width: 100%;
    padding: 0;
    div, .page-embed {
      width: 100%;
      min-height: 100vh;
    }
  }
}
.embed-youtube {
  position: relative;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}
.embed-instagram, .instagram-media  {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: calc(100% - 0.5rem)!important;
  min-width: calc(100% - 0.5rem)!important;
}
.embed-instagram > div {
  display: inline-block;
}
.embed-youtube:before {
  content: '';
  display: block;
  padding-top: 66%;
}
.embed-youtube iframe {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: ${props => props.maxWidth ? props.maxWidth + 'px' : '1280px'}) {
  section, blockquote {
    max-width: 768px;
  }
}
${props => props.scale}
`;
const Global = (props) => {
  const fontName = getFamilyName(props.family);
  const fontHeaderName = getFamilyName(props.font_family_header);
  const scale = props.scale || '1.33'
  return <GlobalStyle
    font={props.font}
    font_family={props.family}
    font_family_header={props.font_family_header}
    font_name_header={fontHeaderName}
    font_name={fontName}
    color={props.color}
    header_color={props.header_color}
    scale={SCALE_CONFIG()[scale]}
    maxWidth={props.maxWidth}
    layout_background_color={props.layout_background_color}
    page_background_color={props.page_background_color}
  />
}

export default Global;
